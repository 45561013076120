import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons/faCircleXmark';
import debounce from 'lodash/debounce';

import Box from 'components/forms/Box';
import Heading from 'libs/heading';
import Spacing from 'layouts/Spacing';
import InputField from 'components/forms/InputField';
import Offer from './Offer';
import { usePopup } from 'context/PopupProvider';
import useWindow from 'context/useWindow';

const Wrap = styled.div``;

const Grid = styled.div`
	${p =>
		p.theme.media.medium(css`
			display: flex;
			flex-wrap: wrap;
			gap: 40px;
		`)}
	article {
		${p =>
			p.theme.media.large(css`
				max-width: calc(50% - 20px);
				/* Target the 1st, 2nd, and 3rd articles in every group of five.  */
				&:nth-child(5n + 1),
				&:nth-child(5n + 2),
				&:nth-child(5n + 3) {
					flex: 1 1 calc(100% / 3 - 40px);
				}

				/* Target the 4th and 5th articles in every group of five */
				&:nth-child(5n + 4),
				&:nth-child(5n + 5) {
					flex: 1 1 calc(100% / 2 - 40px);
				}
			`)}
		${p =>
			p.theme.media.mediumOnly(css`
				flex: 1 1 calc(100% / 2 - 40px);
				max-width: calc(100% - 20px);
			`)}
		${p =>
			p.theme.media.smallOnly(css`
				margin-bottom: 45px;
			`)}
		h3 {
			margin-bottom: 10px;
		}
	}
`;

const SearchClear = styled.button`
	background-color: transparent;
	border: 0;
	margin-left: auto;
	svg {
		width: 17px;
		height: 26px;
		color: ${p => p.theme.colors.grey700};
	}
	&:hover svg {
		color: ${p => p.theme.colors.blue600};
	}
`;

/**
 * Fordelsprogram component, used to display a list of offers
 * @param {array} offers - The offers to display
 * @param {object} props - The props
 * @returns {JSX.Element}
 */
export default function Fordelsprogram({ offers = [], ...props }) {
	const [searchTerm, setSearchTerm] = useState('');
	const [loading, setLoading] = useState(false);
	const [searchResults, setSearchResults] = useState([]);
	const inputRef = useRef(null);

	const { setActivePopup, popupTrigger } = usePopup();
	const { isLoading: isPageLoading } = useWindow();

	/**
	 * Function to handle search, debounced to avoid multiple calls
	 * @param {string} value - The value to search for
	 * @returns {void}
	 **/
	const handleSearch = debounce(value => {
		setLoading(true);
		setSearchTerm(value);
	}, 250);

	/**
	 * Function to handle clearing the search
	 * @returns {void}
	 */
	function handleClear() {
		setSearchTerm('');
		inputRef.current.value = '';
		setSearchResults(offers);
	}

	// Filter the offers based on the search term
	useEffect(() => {
		setLoading(true);
		// Filter the offers based on the search term
		const results = offers.filter(
			offer =>
				offer?.title
					?.toLowerCase()
					?.includes(searchTerm.toLowerCase()) ||
				offer?.shortDesc
					?.toLowerCase()
					?.includes(searchTerm.toLowerCase())
		);

		if (results) {
			setSearchResults(results);
		} else {
			setSearchResults([]);
		}
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm, offers?.length]);

	// Open popup if offer id is in url
	useEffect(() => {
		if (isPageLoading || props?.hideofferbuttons === 'true') return;

		// Get the offer id from the url
		const urlParams = new URLSearchParams(props?.location?.search);
		const offer = urlParams.get('fordel');
		if (!offer) return;

		// Find the offer with the id
		const findOffer = offers?.find(o => o?.offer_id === offer);
		if (!findOffer) {
			console.error(
				`No offer with id ${offer} found, didn't open any popup`
			);
			return;
		}
		popupTrigger.current = 'Page Load'; // Set the trigger to page load (for tracking)
		setTimeout(() => {
			setActivePopup(offer);
		}, 500);

		//eslint-disable-next-line
	}, [isPageLoading, location?.search]);

	return (
		<Wrap {...props}>
			<Spacing spacing={{ bottom: 'small' }}>
				<Box>
					<Heading level="h2" className="h3">
						Leter du etter noe spesielt?
					</Heading>
					<InputField
						ref={inputRef}
						type="search"
						id="offers-search"
						name="offers-search"
						label="Søk etter fordel eller rabatt"
						onChange={e => handleSearch(e?.target?.value)}
						loading={loading}
						clear={
							!loading &&
							searchTerm && (
								<SearchClear
									onClick={handleClear}
									title="Klikk for å tømme søket">
									<FontAwesomeIcon icon={faCircleXmark} />
								</SearchClear>
							)
						}
						icon={(!searchTerm && faSearch) || null}
					/>
				</Box>
			</Spacing>
			<Spacing spacing={{ top: 'small', bottom: 'none' }}>
				<Grid>
					{searchResults?.map((offer, index) => (
						<Offer
							key={index}
							hideButton={props?.hideofferbuttons === 'true'}
							{...offer}
						/>
					))}
				</Grid>

				{searchTerm && !searchResults.length > 0 && (
					<p>
						Beklager, vi fant ingen fordel eller rabatter med dine
						søkeord. Kanskje du kan prøve et annet søkeord?
					</p>
				)}
			</Spacing>
		</Wrap>
	);
}
