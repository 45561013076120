import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import LazyImage from 'components/LazyImage';
import Heading from 'libs/heading';
import Button from 'components/forms/Button';
import Popup from 'components/popup/Popup';
import { usePopup } from 'context/PopupProvider';
import { ContentToRichText } from 'libs/content';
import SanitizeHtml from 'libs/SanitizeHtml';
import BlankButton from 'components/forms/BlankButton';
import SlickSlider from 'components/slick-slider/SlickSlider';

const Image = styled.div`
	padding-bottom: 20px;
	img {
		border-radius: ${p => p.theme.utils.borderRadius};
	}
	svg {
		max-width: 100%;
	}
`;

const PopupInner = styled.div`
	background-color: ${p => p.theme.colors.white};
	border-radius: ${p => p.theme.utils.borderRadius};

	.multiple-images,
	.singular-image {
		padding-bottom: 0;
		border-radius: 0 !important;
		.lazy-image,
		img {
			border-radius: 0 !important;
		}
	}
`;

const PopupContent = styled.div`
	padding: 45px;
	${p =>
		p.theme.media.smallOnly(css`
			padding: 30px;
		`)}
	p:not(:last-child) {
		margin-bottom: 10px;
	}
`;

const NoImage = styled.div`
	width: 100%;
	max-width: 100%;
	height: 0;
	position: relative;
	background: ${p => p.theme.colors.blue100};
	border-radius: ${p => p.theme.utils.borderRadius};
	box-sizing: content-box;
	padding-bottom: 62.5%; /* Aspect ratio 16:10 (10 / 16 * 100) = 62.5% */
	span {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		text-align: center;
		left: 0;
		right: 0;
		color: ${p => p.theme.colors.blue500};
		font-size: 20px;
		padding: 0 20px;
		font-weight: 500;
	}
`;

const HowToUse = styled.div`
	margin-top: 20px;
	background-color: ${p => p.theme.colors.green200};
	padding: 20px 15px;
	border-radius: ${p => p.theme.utils.borderRadius};
`;

const Actions = styled.div`
	display: flex;
	flex-flow: column;
	align-items: flex-end;
	margin-top: ${p => p.theme.spacing.desktop.xsmall};
	${p =>
		p.theme.media.smallUp(css`
			margin-top: ${p => p.theme.spacing.desktop.small};
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
		`)}
	.close-btn {
		align-self: flex-start;
		${p =>
			p.theme.media.smallUp(css`
				margin-right: 35px;
				align-self: center;
			`)}
	}
	a {
		margin-top: 20px;
		max-width: fit-content;
		${p =>
			p.theme.media.smallUp(css`
				margin-top: 0;
			`)}
	}
`;

/**
 * Offer component, used to display a single offer with image, title, short description and a button, and a popup with more information.
 * @param {object} props - The props
 * @param {boolean} props.hideButton - Hide the button that opens the popup
 * @returns {JSX.Element}
 */
export default function Offer({ hideButton = false, ...props }) {
	const { activePopup, setActivePopup, popupTrigger } = usePopup();
	const [triggerClose, setTriggerClose] = useState(false);

	// Get first image from images array
	const mainImage = (props?.images?.length > 0 && props?.images[0]) || {};

	return (
		<>
			<article id={`offer-${props?.offer_id}`}>
				<Image>
					{(mainImage?.file?.url && (
						<LazyImage
							{...mainImage}
							ratio="16:10"
							alt={props?.title}
						/>
					)) || (
						<NoImage>
							<span>{props?.title}</span>
						</NoImage>
					)}
				</Image>
				<div>
					<Heading level="h3">{props?.title}</Heading>
					<p>{props?.shortDesc}</p>
					{!hideButton && (
						<Button
							outlined="true"
							small={true}
							title="Les mer og benytt tilbud"
							onClick={() => {
								popupTrigger.current = 'Click';
								setActivePopup(props?.offer_id);
							}}
							aria-haspopup={true}
							aria-controls={props?.offer_id}
							aria-expanded={activePopup === props?.offer_id}
							tracking={false}>
							Les mer og benytt tilbud
						</Button>
					)}
				</div>
			</article>
			{!hideButton && (
				<Popup
					id={props?.offer_id}
					title={props?.title}
					size="small"
					padding="false"
					closeButton="circular"
					context={props?.popupContext}
					triggerClose={triggerClose}>
					<PopupInner>
						{(props?.images?.length > 0 && (
							<SlickSlider
								layout="default"
								images={props?.images}
								dots={false}
								arrows={true}
								arrowsposition="corner"
								autoplay={false}
								fade={true}
								aria-label={`Bilder av ${props?.title}`}
								load="instant"
								infinite={false}
								fit="fill"
							/>
						)) || (
							<NoImage>
								<span>{props?.title}</span>
							</NoImage>
						)}
						<PopupContent>
							<h3>{props?.title}</h3>
							{(props?.description?.raw && (
								<ContentToRichText data={props?.description} />
							)) || <p>{props?.shortDesc}</p>}

							{props?.howToUse && (
								<HowToUse>
									<SanitizeHtml html={props?.howToUse} />
								</HowToUse>
							)}
							<Actions>
								<BlankButton
									className="close-btn"
									aria-controls={props?.offer_id}
									onClick={() => setTriggerClose(true)}>
									Lukk og se alle tilbud
								</BlankButton>
								{props?.button && (
									<Button {...props?.button}>
										{props?.button?.text}
									</Button>
								)}
							</Actions>
						</PopupContent>
					</PopupInner>
				</Popup>
			)}
		</>
	);
}
